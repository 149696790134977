<template>
  <div class="church-list template-1">
    <header class="page-header container">
      <h1>{{ translations.tcAuxiliaryPlacementLocations }}</h1>
    </header>
    <!-- / Page Header -->
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <camp-selector
        @camp_changed="page_load($event)"
        :tooltip="translations.tcCurrentHome"
        :i18n="translations.components"
      ></camp-selector>
      <!-- / Camp Selector -->

      <data-table
        :fields="fields"
        :items="placementLocationsTranslated"
        :hiddenItems="hiddenItems"
        :addItems="addItems"
        :i18n="translations.components"
        @addLocation="addLocation"
        :csvUrl="locationListCSVUrl"
        :pdfUrl="locationListPDFUrl"
        :reportName="reportName"
      ></data-table>
      <!-- / Data Tables -->

      <footer class="page-footer"></footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTableScripture.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'auxiliary-placement-locations',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      member_types: [
        { text: 'All', value: '9084a3c7-bbf0-4796-b4a9-5b5d95349d4c' },
        { text: 'Auxiliary', value: '678cfa9f-3ced-4880-ab74-6bad634aaf74' },
        { text: 'Both', value: '80a3afcf-4def-4ccc-85b2-96284f978027' },
        { text: 'Gideon', value: 'abda920a-f939-4de0-bad5-0ab91f476b5e' },
      ],
      fields: [
        { key: 'facility_name', label: '', sortable: true },
        { key: 'institution_type', label: 'Institution Type', sortable: true },
        {
          key: 'camp_assigned_number',
          label: 'Assigned Number',
          sortable: true,
        },
        { key: 'member_type', label: '', sortable: true },
        { key: 'city', label: '', sortable: true },
        {
          key: 'last_presentation_date',
          label: '',
          sortable: true,
          formatter: (value, key, item) => {
            return !!value ? value.slice(0, 10) : ''
          },
        },
      ],
      hiddenItems: {
        display: false,
      },
      addItems: {
        display: true,
        text: 'Add Location',
        action: 'addLocation',
      },
      selectedMemberType: '678cfa9f-3ced-4880-ab74-6bad634aaf74', //DEFAULT TO GET ALL
      secured_facility_add_controls: {
        add_aux_facility_button: 'c47e208a-ae11-47a9-b989-863076477420',
      },
      reportName: 'PlacementLocations',
    }
  },
  methods: {
    ...mapActions({
      clearLocationDetails: 'scriptureDistribution/clearLocationDetails',
      loadAddressFormOptions: 'membership/loadAddressFormOptions',
      loadChurchListReportUrls: 'churchMinistry/loadChurchListReportUrls',
      loadPlacementLocationsByCamp: 'scriptureDistribution/loadPlacementLocationsByCampForAuxiliary',
      loadPlacementLocationsByCampAsCSVUrl: 'scriptureDistribution/loadPlacementLocationsByCampAsCSVUrl',
      loadPlacementLocationsByCampAsPDFUrl: 'scriptureDistribution/loadPlacementLocationsByCampAsPDFUrl',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedLocationKey: 'user/setSelectedLocationKey',
    }),
    async page_load(evt) {
      if (!evt) {
        try {
          this.setLoadingStatus(true)
          await Promise.all([
            await this.setSelectedLocationKey(''),
            await this.loadPlacementLocationsByCamp(this.selectedMemberType),
            await this.loadPlacementLocationsByCampAsPDFUrl(this.selectedMemberType),
            await this.loadPlacementLocationsByCampAsCSVUrl(this.selectedMemberType),
            await this.loadChurchListReportUrls({ hidden: null, ineligible: null }),
          ])
        } catch (e) {
          console.error(e)
        } finally {
          this.setLoadingStatus(false)
        }
      }
    },
    async addLocation() {
      try {
        await Promise.all([
          this.setLoadingStatus(true),
          await this.setSelectedLocationKey(''),
          await this.clearLocationDetails(),
          await this.loadAddressFormOptions(),
        ]).then(() => {
          this.$router.push('/programs/sd/location-profile/add-location/link')
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    loadTranslations() {
      this.addItems.text = this.translations.tcAddLocation
      this.fields[0].label = this.translations.tcName
      this.fields[1].label = this.translations.tcInstitutionType
      this.fields[2].label = this.translations.tcAssignedNumber
      this.fields[3].label = this.translations.tcMemberType
      this.fields[4].label = this.translations.tcCity
      this.fields[5].label = this.translations.tcLastPresentationDate
    },
  },
  computed: {
    ...mapGetters({
      iCanSeeAny: 'user/iCanSeeAny',
      isInMenu: 'menu/isInMenu',
      locationListPDFUrl: 'scriptureDistribution/locationListPDFUrl',
      locationListCSVUrl: 'scriptureDistribution/locationListCSVUrl',
      placement_locations: 'scriptureDistribution/placement_locations_auxiliary',
      prefCulture: 'user/userPreferredCulture',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path)
    },
    placementLocationsTranslated() {
      return this.placement_locations.map((location) => {
        if (!location || !location.mpt_key || !location.institution_type_key || !this.translations.common)
          return location
        const memberType = this.translations.common['member-types'].hasOwnProperty(location.mpt_key)
          ? this.translations.common['member-types'][location.mpt_key]
          : location.member_type

        const institutionType = this.translations.common['placement-facility-types'].hasOwnProperty(
          location.institution_type_key
        )
          ? this.translations.common['placement-facility-types'][location.institution_type_key]
          : location.institution_type

        return { ...location, institution_type: institutionType, member_type: memberType }
      })
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      this.addItems.display = this.iCanSeeAny(this.secured_facility_add_controls)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('camp-select', 'data-table-scripture', 'security-banner'),
        this.getComponentTranslations('common.member-types', 'common.placement-facility-types'),
      ]).then((results) => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common },
        }
        this.$set(this.translations, 'components', translatedText)
        this.loadTranslations()
      })
      await this.page_load()
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
    securityBanner: SecurityBanner,
  },
}
</script>

<style lang="scss">
/* eslint-disable */
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';
</style>
